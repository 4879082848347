@mixin page-home--xs() {
    .page-home {
        background: transparent;
    }
}

@mixin page-home-navbar--xs() {
    .navbar {
        .page-home & {
            margin-bottom: 20px;
        }

        .navbar-nav {
            margin: 20px 0 0;
            background: #CDC6C0;

            &.pull-right {
                float: left!important;
                width: 100%;
                margin: 0;
            }

            li {
                display: block;
                padding: 0;

                a {
                    display: block;
                    padding: 10px;
                    color: #000!important;
                    border-bottom-color: #DDD!important;

                    &.active, &:hover, &:active {
                        background: lighten(#CDC6C0, 2);
                    }
                }

                .submenu {
                    padding: 0;
                    margin: 0;

                    & > li {
                        list-style: none;

                        & > a {
                            letter-spacing: 1.5px;
                            font-weight: 300;
                            font-size: 1.4rem;
                            padding: 10px 5px 10px 34px;
                            background: lighten(#CDC6C0, 12);
                            border-bottom: 1px solid #FFF!important;;

                            &:hover {
                                background: lighten(#CDC6C0, 10);
                            }
                        }

                        &.dropdown-header {
                            margin: 0;
                            padding: 0;

                            a {
                                padding: 10px 0 10px 20px;
                                font-size: 1.45rem;
                                font-weight: 400;
                                border-bottom-color: #EEE!important;;
                                background: lighten(#CDC6C0, 6);

                                &:hover {
                                    background: lighten(#CDC6C0, 3);
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-header {
            display: block;
            text-align: center;
            padding: 0;

            .navbar-brand {
                display: block;
                padding: 20px;
                text-align: center;
                margin: 0;
                position: relative;
                float: left;
            }
        }
    }

    .cart {
        &.cart-mobile {
            display: block;

            .cart-icon {
                margin: 0;
            }
        }

        &:not(.cart-mobile) {
            display: none!important;
        }
    }
}

@mixin page-home-produto-destaque--xs() {
    .produtos-destaque {
        .produto-destaque {
            width: 100%;
            clear: both;
            margin: 0 0 10px;

            & > img {
                max-width: 100%;
            }

            &:last-child {
                width: auto;
                margin-right: 0;
            }
        }
    }
}

@mixin page-home-banner--xs() {
    .banner {
        .page-home & {
            margin: 0;
        }
    }
}

@mixin page-home-full-banner--xs() {
    .full-banner {
        padding: 10px 5px;

        .separator {
            display: block;
            height: 1px;
            color: transparent;
        }
    }
}

@mixin page-home-social-media--xs() {
    .social-media {
        .media-list {
            width: 100%;
            max-width: auto;
            li {
                padding: 0 10px;
            }

            &.mini {
                text-align: center;
            }
        }

        .big-instagram {
            font-size: 1.7rem;
        }
    }
}

@mixin page-home-instagram-preview--xs() {
    .instagram-preview {
        margin: 0 0 30px;

        .instagram-preview-list {
            &:after {
                display: block;
                clear: both;
                height: 1px;
                content: ' ';
            }

            li {
                width: 50%;
                float: left;

                & + li {
                    margin-left: 0;
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
}

@mixin page-home-menu-footer--xs() {
    .container-footer {
        padding: 0 20px;

        .footer {
            padding: 0;
            margin-top: 20px;

            .powered-by {
                width: 100%;
                display: block;
                clear: both;
                margin-right: 0;
            }
        }
    }

    .menu-footer {
        margin: 0 0 20px;
        padding: 0;
        text-align: center;

        &:after {
            display: block;
            clear: both;
            height: 1px;
            content: ' ';
        }

        li {
            width: 100%;
            clear: both;
        }
    }
}

@mixin page-home-produto--xs() {
    .dropdown.order {
        width: 100%!important;
        clear: both!important;
        margin: 0!important;
    }

    .menu {
        & > li {
            & > a {
                padding: 8px 10px!important;
                text-align: center;
                border: 1px solid #E1E1E1;
            }
        }

        .submenu {
            margin: 0!important;
            background: #E9E9E9;

            li {
                padding: 0;
                margin: 0;
            }

            a {
                text-align: center;
                border-bottom: 1px solid #DDD;
                padding: 0;
            }
        }
    }

    .fotos {
        .carousel-control {
            img {
                max-width: 100%;
            }

            &.right {
                margin-right: 15px;
            }
        }

        .ampliar {
            left: 10px!important;
        }

        .minis {
            li {
                width: 32%!important;
            }
        }
    }

    .form-cep {
        .form-group {
            width: 100%!important;
        }

        .btn {
            width: 100%!important;
            margin-left: 0!important;
        }
    }
}

@mixin page-home-menu-hover--md() {
    .navbar &.dropdown:hover {
        & > ul.dropdown-menu {
            display: block;
        }
    }
}

@mixin page-home-cart--md() {
    .cart {
        &.cart-mobile {
            display: none;

            .cart-icon {
                margin: 0;
            }
        }

        &:not(.cart-mobile) {
            display: block;
        }
    }
}
