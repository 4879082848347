@import "mixins";

/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) and (max-width : 479px) {
    @include page-home--xs();
    @include page-home-navbar--xs();
    @include page-home-produto-destaque--xs();
    @include page-home-banner--xs();
    @include page-home-full-banner--xs();
    @include page-home-social-media--xs();
    @include page-home-instagram-preview--xs();
    @include page-home-menu-footer--xs();
    @include page-home-produto--xs();
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) and (max-width : 767px) {
    @include page-home--xs();
    @include page-home-navbar--xs();
    @include page-home-produto-destaque--xs();
    @include page-home-banner--xs();
    @include page-home-full-banner--xs();
    @include page-home-social-media--xs();
    @include page-home-instagram-preview--xs();
    @include page-home-menu-footer--xs();
    @include page-home-produto--xs();
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 991px) {
    @include page-home-menu-hover--md();
    @include page-home-cart--md();
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) and (max-width : 1199px) {
    @include page-home-menu-hover--md();
    @include page-home-cart--md();
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    @include page-home-menu-hover--md();
    @include page-home-cart--md();
}
