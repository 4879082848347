body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
}

.header {
    padding: 15px 0;
    text-align: center;
    border-bottom: 1px solid #eee;
    background: #f4f4f4;
    margin: 0 0 20px;

    .brand {
        display: block;

        img {
            max-height: 60px;
        }
    }
}

.footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
}

.title {
    font-size: 6rem;
    text-align: center;
    text-transform: uppercase;
}

.list-footer {
    text-align: center;
    font-size: 2rem;
    margin-top: 20px;
}

.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;

    .item {
        width: calc(50% - 20px);
        padding: 20px;
        background: #EEE;
        border-radius: 5px;
        margin: 10px;
        
        .title {
            font-size: 3rem;
        }

        .number {
            font-size: 10rem;
            font-weight: bold;
        }

        & + .item {
            border-left: 1px solid #ccc;
        }
    }
}

.loader {
    font-size: 2rem;
    text-align: center;
}